// Our main CSS
import '../css/app.css'
// layzyload and/or play/pause inline videos when they are in/out of view

/*
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}
*/
/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

import { createApp } from 'vue'



/**
 * 🦄: Now do your magic.
 */



import('./quoteSlider').then((module) => {
  module.default()
})


const menu = createApp({

  data: () => ({
    active: null,
    modalActive: null,
  }),

  watch: {
    active: {
      handler(newValue) {
        if (newValue === null) {
          return document.body.classList.remove('menu-open', 'overlay');
        }
        return document.body.classList.add('menu-open')
      },
      immediate: true
    }
  },

  methods: {
    toggle(name) {
      document.body.classList.add('overflow-hidden')
      if (this.active === name) {
        document.body.classList.remove('overflow-hidden')
        return this.active = null
      }
      this.active = name
    },
    handleScroll() {
      const nav = this.$refs.nav;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > 2) {
        nav.classList.add('scrolled');
      }
      else {
        nav.classList.remove('scrolled');
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
})

menu.mount('#main-menu')

// document ready
document.addEventListener('DOMContentLoaded', () => {

  const employeeFilter = createApp({

    data: () => ({
      employees: [], // This will hold the PHP employee data
      filteredEmployees: [],
      activeFilter: null,
      location: 'location',
      locationName: 'Location',
      department: 'department',
      departmentName: 'Department',
      searchQuery: '',
    }),

    methods: {
      toggleLocation(slug, name) {
        this.location = slug
        this.locationName = name
        this.applyFilters();
        this.toggleFilter(null)
      },
      toggleDepartment(slug, name) {
        this.department = slug
        this.departmentName = name
        this.applyFilters();
        this.toggleFilter(null)
      },
      toggleFilter(name) {
        if (this.activeFilter === name) {
          return this.activeFilter = null
        }
        this.activeFilter = name
      },
      applyFilters() {
        this.filteredEmployees = this.employees.filter(employee => {
          const matchesDepartment = this.department === 'department' || employee.departments.some(dep => dep.slug === this.department);
          const matchesLocation = this.location === 'location' || employee.locations.some(loc => loc.slug === this.location);
          const matchesSearchQuery = employee.name.toLowerCase().includes(this.searchQuery.toLowerCase()); // New filter condition for search

          return matchesDepartment && matchesLocation && matchesSearchQuery;
        });
      }
    },
    mounted() {
      // Assuming PHP passes JSON data to a JavaScript variable
      this.employees = employeeData; // Use your PHP-generated JSON data
      this.filteredEmployees = this.employees;
    }
  })

  employeeFilter.mount('.js-employee-filter')
})
